import React, { Component } from 'react';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import api from './services/api';
import {Content} from './styles'
import Upload from './components/Upload'
import FileList from './components/FileList'


class App extends Component {
  state={
    uploadedFiles:[],
  };

  async componentDidMount(){
    const {data} = await api.get('posts');
    this.setState({
        uploadedFiles: data?.posts?.map(file=>({
          id:file._id,
          name:file.name,
          readableSize: filesize(file.size),
          preview:file.url,
          date:file.createdAt,
          uploaded:true,
          url:file.url,
        }))
    })
  }

  handleUpload = files =>{
    const uploadedFiles= files.map(file=>({
      file,
      id:uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      date:file.createdAt,
      progress:0,
      uploaded:false,
      error:false,
      url: null,
    }))

  this.setState({
    uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles)
  });
  uploadedFiles.forEach(this.processUpload);
  };

  updateFile = (id, data)=>{
    this.setState({ uploadedFiles: this.state.uploadedFiles.map(uploadedFile=> {
      return id===uploadedFile.id ? {...uploadedFile, ...data}: uploadedFile;
    })});

  };
  processUpload = (uploadedFile) =>{
    const data = new FormData();

    data.append('file', uploadedFile.file, uploadedFile.name);

    try {
      api.post('posts', data, {
        onUploadProgress: e=>{
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));
  
          this.updateFile(uploadedFile.id,{
            progress,
          })
        }
      }).then(response=>{
        console.log(response)
          this.updateFile(uploadedFile.id,{
            uploaded: true,
            id: response.data.post._id,
            url: response.data.post.url,
          });
      }).catch(()=>{
        this.updateFile(uploadedFile.id,{
          error: true
        });
    })
    } catch (error) {
      
    }
  };

  handleDelete = async id=> {
      await api.delete(`posts/${id}`);
        this.setState({
      uploadedFiles: this.state.uploadedFiles.filter(file=> file.id!==id),
      
    })
  }

componentWillUnmount(){
  this.state.uploadedFiles.forEach(file => URL.revokeObjectURL(file.preview));

}

  render(){
    const {uploadedFiles} = this.state;
    return (

        <Content>
          <Upload onUpload={this.handleUpload} />
          {!!uploadedFiles.length && (
            <FileList files={uploadedFiles} onDelete={this.handleDelete}/>
          )}
        </Content>
    )
  }
}

export default App;
