import styled from 'styled-components';

export const Topo1 = styled.div`
display: flex;
background-color: #223b69;
width:100%;
height: 203.58px;
flex-direction:row;
justify-content:center;

`;

export const Topo2 = styled.div`
display: flex;
align-items:center;
flex-direction:row;
justify-content: center;
background-color: #000c22;
height: 79px;
margin:0px;

h1{
    font-size: 36px;
    color:#ffffff;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    margin:0px;
    
    text-transform: uppercase;
    

}
`;