import React, {Fragment} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter} from "react-router-dom";
import Routes from './Routes/Routes';
import Header from './components/Header';
import GlobalStyle from './styles/global'
import { Container } from './styles'
import {Provider as ReduxProvider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';
import {persistor, store} from './redux/store';


const App = ()=>{

  return(
    <Fragment>
        <Header />
        <Container>
          <Routes />
        <GlobalStyle />
        </Container>

    </Fragment>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
    <BrowserRouter>
    <App />
    </BrowserRouter>
    </PersistGate>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

