import React from "react";
import {Switch,Route} from "react-router-dom";
import Uploads from "../Uploads";
import Login from "../Login";
import useAuth from "../hooks/useAuth";




export default function Routes(){
    const {token} = useAuth(useAuth)
        
    return(
        
        <Switch>
            <Route path="/">
                {!token?<Login />:<Uploads />}
            </Route>
        </Switch>
        
    )
}