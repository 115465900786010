import styled from 'styled-components'

export const Container = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
`;

export const Content = styled.div`
    width:100%;
    max-width:800px;
    border-radius:4px;
    max-height:800px;
    padding: 20px;
    margin-bottom:250px;
    max-height: 700px;
    box-shadow: 0px 1px 9px 1px rgba(34,59,105,1);
    -webkit-box-shadow: 0px 1px 9px 1px rgba(34,59,105,1);
    -moz-box-shadow: 0px 1px 9px 1px rgba(34,59,105,1);

`
export const Form = styled.form`
        margin-bottom: 500px !important

`
