import React,{useState} from 'react'
import api from './services/api'
import { makeStyles, TextField, Button } from '@material-ui/core';
import useAuth from './hooks/useAuth';
import {Form} from './styles'


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
      
    },
  },
}));

export default function Login() {
    const [loginUsername, setLoginUsername] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const {setToken} = useAuth()


      const login = async (e) => {
        e.preventDefault()
        try {
          const {data} = await api({
            method: "POST",
            data: {
              username: loginUsername,
              password: loginPassword,
            },
            withCredentials: true,
            url: "/login",
          })
          if (data?.token) {
              //localStorage.setItem("token", data.token)
              setToken(data.token)
          }
            
        } catch (error) {
          
        }
      };
      
      const classes = useStyles();
    return (

    <div>
      <div>
        <h1>Faça seu login</h1><br/>
        <Form onSubmit={login} className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" label="Usuário" variant="outlined" placeholder="username"
              onChange={(e) => setLoginUsername(e.target.value)} /><br/>
              <TextField id="outlined-basic" label="Senha" variant="outlined" placeholder="username"
              onChange={(e) => setLoginPassword(e.target.value)} /><br/>
              
              <Button variant="outlined" color="primary" type="submit">Entrar</Button>
        </Form>
      
      </div>

    </div>

    )
}


