import React, { Component, Fragment } from 'react';
import {Topo1, Topo2} from "./styles";

class Header extends Component {
  render(){
    return(
      <Fragment>
      <Topo1>
        <img alt="blue empreendimentos" src="https://informativo.hqssolucoes.com.br/imagens/logo-blue.svg" width="280px"/>
      </Topo1>
      <Topo2>
        <h1>Gerador de Link</h1>
      </Topo2>
      </Fragment>
    )
  }
};


export default Header;